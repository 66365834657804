import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import ButtonRounded from "./Button";
import { useNavigate } from "react-router-dom";

interface IProps {
  status: "info" | "warning" | "success" | "error" | undefined;
  title: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  description : string;
  height?: string;
}



function AlertBox({status="info", title, description, height="200px"}: IProps) {

  const navigate = useNavigate();
  return (
        <Alert
        status={status}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height={height}
        >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
            {title}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
            {description}
            <br />
            <ButtonRounded label="Go Back" severity="secondary" onClick={()=>navigate(-1)} />
        </AlertDescription>
        </Alert> 
  )
}

export default AlertBox



