import { ReactNode } from "react"

interface IProps {
    children: ReactNode
}

function TabBar({children}: IProps) {
  return (
      <div className="card">
              <div>
                  {children}
            </div>
        </div>
  )
}

export default TabBar
