import axios from 'axios';
import { environments } from 'constants/environments';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const apiClient = axios.create({
    baseURL: environments.domain,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
    }
});

// Request interceptor
apiClient.interceptors.request.use(
    (config) => {
        let token:any;
        if (typeof window !== 'undefined') {
             token = localStorage.getItem(environments.tokenKey); 
        }
        if (token) {
            config.headers.token = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            console.log("error.response",error.response);
            
            if (error.response.status === 401) {
                history.push('/admin/auth/login');
            }
        }
        return Promise.reject(error);
    }
);

export default apiClient;
