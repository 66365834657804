
import 'assets/css/badges.css';
import GalleriaImages from "shared/Galleria";
export const dateCreatedAtBodyTemplate = (rowData: any) => {
  const date = rowData.createdAt;
  return <>{new Date(date).toLocaleString()}</>;
};

export const galleriaImagesBodyTemplate = (rowData: any) => {
  return (<>
    <GalleriaImages />
    </>)
};

export const dateUpdatedAtBodyTemplate = (rowData: any) => {
  const date = rowData.createdAt;
  return <>{new Date(date).toLocaleString()}</>;
};



export const statusActiveBodyTemplate = (rowData: any) => {
  return (
    <span
      className={`active-badge status-${rowData.isActive ? "active" : "unactive"}`}
    >
      {rowData.isActive ? "Active" : "Not Active"}
    </span>
  );
};



