import { z } from "zod";

export const createBookSchema = z.object({
    title : z.string().max(50,{message:"Title is Too Long"}),
    supTitle : z.string().max(150,{message:"Title is Too Long"}),
    description : z.string(),
    subCategory : z.string(),
    keywords : z.any(z.string()).optional(),
    type : z.string().optional(),
    cover : z.any().optional(),
    doc : z.any().optional(),
    isExternal: z.boolean().optional(),
    attachments: z.array(z.object({
        typeAttachment: z.string().nonempty({ message: "Attachment type is required" }),
        name: z.string().nonempty({ message: "Attachment name is required" }),
        url: z.string().nonempty({ message: "Attachment URL is required" })
      })),
    externalLink: z.string().optional(),
    isTopView: z.boolean().default(false).optional(),
    isActive: z.boolean().optional(),
});

export const updateBookSchema = z.object({
    title : z.string().max(50,{message:"Title is Too Long"}).optional(),
    supTitle : z.string().max(150,{message:"Title is Too Long"}).optional(),
    description : z.string().optional(),
    subCategory : z.string().optional(),
    keywords : z.array(z.string()).optional(),
    type : z.string().optional().optional(),
    cover : z.any().optional().optional(),
    doc : z.any().optional().optional(),
    isExternal: z.boolean().optional(),
    attachments: z.array(z.object({
        typeAttachment: z.string().nonempty({ message: "Attachment type is required" }).optional(),
        name: z.string().nonempty({ message: "Attachment name is required" }).optional(),
        url: z.string().nonempty({ message: "Attachment URL is required" }).optional()
      })),
    externalLink: z.string().optional(),
    isTopView: z.boolean().default(false).optional(),
    isActive: z.boolean().optional(),
});




export type ICreateBookInputs = z.infer<typeof createBookSchema>
export type IUpdateBookInputs = z.infer<typeof updateBookSchema>
