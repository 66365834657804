import { IHeadersDataPagination } from "interfaces/pagination";
import { IColumnMeta } from "interfaces/table";
import { useEffect, useState } from "react";
import { dateCreatedAtBodyTemplate, dateUpdatedAtBodyTemplate, statusActiveBodyTemplate } from "utils/bodyTemlates";
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import Table from "shared/Table";
import PaginatorPages from "shared/Paginator";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import AlertBox from "shared/AlertBox";
import TabBar from "shared/TabBar";
import ShortImage from "shared/ShortImage";
import { Link } from "react-router-dom";
import ButtonRounded from "shared/Button";
import { IItem } from "interfaces/itmes";
import DeleteMaterial from "./components/DeleteMaterial";

const Materials = () => {

  const [data, setData]                 = useState<any[]>([]);
  const [pagination, setPagination]     = useState<IHeadersDataPagination>({paginationcurrent: 1,paginationlimit: 20,paginationtotal: 0,});
  const [loading, setLoading]           = useState<boolean>(true);
  const [isError, setIsError]           = useState(false);
  const {sharedData}                    = useAuth()
  const booksAPI                        = environments.domain + sharedData?.apisList?.WebSite?.Item?.General
    
  useEffect(() => {
    getData();
  }, [pagination.paginationcurrent, pagination.paginationlimit]);

  const reFetch = () => {
    getData()
  };

  const getData = async () => {
    setLoading(true);
    const response : IApiMetaData = await callApi("GET", booksAPI,{},pagination,{populatefields:"addedBy updatedBy subCategory",populateselected:"name"});
    
    if (response.Status === 200) {
      setPagination((prevPagination: any) => ({
        ...prevPagination,
        paginationtotal: response.Pagination.total,
      }));
      setData(() => response.Data.Content);
    } else {
      setIsError(true);
    }
    setLoading(false);
  };

  const columns: IColumnMeta<IItem>[] = [
    {
      field: "title",
      header: "Title",
    },
    {
      field: "subCategory",
      header: "Category",
      body: (data: any) => data?.subCategory?.name,
    },
    {
      field: "keywords",
      header: "Keywords",
      body: (data: any) => data.keywords.join(", "),
    },
    {
      field: "type",
      header: "Type",
    },
    {
      field: "cover",
      header: "Cover",
      body: (data: any) => <ShortImage src={data.cover}/>,
    },
    {
      field: "isActive",
      header: "Status",
      body: statusActiveBodyTemplate,
    },
    {
      field: "addedBy",
      header: "Added By",
      body: (data: any) => data.addedBy?.name
    },
    {
      field: "updatedBy",
      header: "Updated By",
      body: (data: any) => data.updatedBy?.name
    },
    {
      field: "createdAt",
      header: "CreatedAt",
      body: dateCreatedAtBodyTemplate,
    },
    {
      field: "updatedAt",
      header: "updatedAt",
      body: dateUpdatedAtBodyTemplate,
    },
    {
      field: "edit",
      header: "Edit",
      body: (bookData: any) => {
        return (
          <Link  to={"/admin/material/update"} state={bookData} >
            <ButtonRounded label="Edit" />
          </Link>
        )
      },
    },
    {
      field: "delete",
      header: "Delete",
      body: (data: any) => {
        return (
          <DeleteMaterial id={data._id} onSave={reFetch}/>
        );
      },
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card className="mb-4">
        <TabBar>
          <Link to={"/admin/material/add"} >
            <ButtonRounded label="Add Book"/>
          </Link>
        </TabBar>
      </Card>
      
      <Card>
        <div style={{ width: "100%" , marginTop: "20px", marginBottom: "20px" }}>
          {isError === false ? (
            <>
              <Box>
                <Table data={data} columns={columns} loading={loading} />
                <PaginatorPages
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </Box>
            </>
          ) : (
            <div className="grid justify-content-center align-content-center">
                <AlertBox status="error" description="Something went wrong. Please try again later." title="Error" />
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};


export default Materials;
