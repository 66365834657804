import { Toast } from "primereact/toast";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useRef } from "react";
import Button from "shared/Button";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";

interface IProps {
  id: string;
  onSave: () => void;
}

const DeleteMaterial = ({ id, onSave }: IProps) => {
  const toast: any = useRef(null);
  const { sharedData } = useAuth();

  const bookAPI =
    environments.domain + sharedData?.apisList?.WebSite?.Item?.General;

  const deleteRecord = async () => {
    const response: IApiMetaData | any = await callApi(
      "DELETE",
      bookAPI,
      {},
      {},
      { id }
    );

    if (response.Status === 200) {
      showToast("success", response?.Message + " Deleted ");
      onSave();
    } else {
      showToast("error", response?.response?.data?.Error);
    }    
  };
  const showToast = (severity: any, detail: string) => {
    toast.current?.show({
      severity: severity,
      summary: String(severity)[0].toUpperCase() + String(severity).slice(1),
      detail: detail,
      life: 3000,
    });
  };

  const accept = () => {
    deleteRecord();
  };

  const confirm2 = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Do you want to delete this record?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
    });
  };

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <ConfirmPopup />
      <div className="card flex flex-wrap gap-2 justify-content-center">
        <Button
          onClick={confirm2}
          icon="pi pi-times"
          label="Delete"
          className="p-button-danger"
        ></Button>
      </div>
    </>
  );
};

export default DeleteMaterial;
