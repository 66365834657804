import React, { useState } from "react";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { IHeadersDataPagination } from "interfaces/pagination";

export default function PaginatorPages({ pagination, setPagination }: any) {
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(pagination.paginationlimit);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPagination((prevPagination: IHeadersDataPagination) => ({
      ...prevPagination,
      paginationcurrent: event.page + 1,
      paginationlimit: event.rows,
    }));
  };

  return (
    <div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={pagination.paginationtotal}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        onPageChange={onPageChange}
      />
    </div>
  );
}
