import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import { useRef } from 'react';

interface IMessageProps {
    message: string,
    severity : string,
}

function Message({message,severity='info'}:IMessageProps) {
    const msgs = useRef<Messages>(null); 
    useMountEffect(() => {
        msgs.current?.clear();
        msgs.current?.show([
            {sticky: true, severity: severity as any, summary: message,}
        ]);
    });


    return (
        <Messages ref={msgs} />
    )
}

export default Message