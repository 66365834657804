import { z } from "zod";

export const createProgramSchema = z.object({
    name : z.string().min(1,{message:"Program Name is Required"}),
    isActive: z.boolean(),
});

export const updateProgramSchema = z.object({
    name : z.string().optional(),
    isActive: z.boolean().optional(),
});




export type ICreateProgramInputs = z.infer<typeof createProgramSchema>
export type IUpdateProgramInputs = z.infer<typeof updateProgramSchema>
