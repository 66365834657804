
import React, { useRef, useState } from "react";
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import { Toast } from "primereact/toast";


interface IProps {
    data: any
}
export default function SelectButtonToggle ({data} : IProps) {
    let isActive = data?.isActive === true ? 1 : 0;
    
    const options: string[]         = ['Un Active', 'Active'];
    const [value, setValue]         = useState<string>(options[isActive]);
    const { sharedData }            = useAuth()
    const toast                     = useRef<Toast>(null);
    const userAPI                   = environments.domain + sharedData?.apisList?.Dashboard?.User?.ChangeStatusUser



    const handlingChange = async (e: SelectButtonChangeEvent) => {
        let statusActive = e.value === 'Active' ? true : false;
        const response: IApiMetaData = await callApi("POST", userAPI, { isActive: statusActive }, {}, { id: String(data?._id), target: "user" }, false);
        if (response.Status === 200) { 
            showToast("success", "Success", "User Status Updated Successfully");
        } else {
            showToast("error", "Error", "User Status Updated Successfully");
        }
        setValue(e.value);

}
const showToast = (severity: string | any,summary: string,detail: string) => {
    toast.current?.show({severity:severity, summary: summary, detail:detail, life: 3000});
}

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} position="bottom-right"/>
            <SelectButton value={value} onChange={handlingChange} options={options} />
        </div>
    );
}
        