import { z } from "zod";

export const addUserSchema = z.object({
    name: z.string().min(1, { message: "Name is Required" }),
    email: z.string().min(1, { message: "Email is Required" }).email({ message: "Invalid Email" }),
    program: z.array(z.string()),
    password: z.string().min(6, { message: "Password must be at least 6 characters" }),
    confirmPassword: z.string().min(6, { message: "Confirm Password must be at least 6 characters" }),
  }).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], 
  });



  export const updateUserSchema = z.object({
    program: z.array(z.string()),

  });




export type IAddUserSchemaInputs = z.infer<typeof addUserSchema>
export type IUpdateUserInputs = z.infer<typeof updateUserSchema>
