import { z } from "zod";

export const createCategorySchema = z.object({
    name : z.string().min(1,{message:"Category Name is Required"}),
    program: z.array(z.string()),
    isActive: z.boolean(),
});

export const updateCategorySchema = z.object({
    name : z.string().optional(),
    program: z.array(z.string()),
    isActive: z.boolean().optional(),
});




export type ICreateCategoryInputs = z.infer<typeof createCategorySchema>
export type IUpdateCategoryInputs = z.infer<typeof updateCategorySchema>
