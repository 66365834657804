import { IHeadersDataPagination } from "interfaces/pagination";
import { IColumnMeta } from "interfaces/table";
import { useEffect, useState } from "react";
import { dateCreatedAtBodyTemplate, dateUpdatedAtBodyTemplate } from "utils/bodyTemlates";
import { Box, } from "@chakra-ui/react";
import Card from "components/card/Card";
import Table from "shared/Table";
import PaginatorPages from "shared/Paginator";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import AlertBox from "shared/AlertBox";
import SelectButtonToggle from "shared/SelectButtonToggle";
import { IUser } from "interfaces/user";
import DialogModel from "shared/DialogModel";
import AddUser from "./components/AddUser";
import TabBar from "shared/TabBar";
import UpdateUser from "./components/UpdateUser";

const Users = () => {

  const [data, setData] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState<IHeadersDataPagination>({ paginationcurrent: 1, paginationlimit: 20, paginationtotal: 0, });
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const { sharedData } = useAuth()
  const usersAPI = environments.domain + sharedData?.apisList?.Dashboard?.User?.General
  const reFetch = () => {
    getData()
  };
  useEffect(() => {

    getData();
  }, [pagination.paginationcurrent, pagination.paginationlimit]);

  const getData = async () => {
    setLoading(true);
    const response: IApiMetaData = await callApi("GET", usersAPI, {}, pagination, { target: "user", populatefields: "program", populateselected: "name" });

    if (response.Status === 200) {
      setPagination((prevPagination: any) => ({
        ...prevPagination,
        paginationtotal: response.Pagination.total,
      }));
      setData(() => response.Data.Content);
    } else {
      setIsError(true);
    }
    setLoading(false);
  };
  const columns: IColumnMeta<IUser>[] = [
    {
      field: "name",
      header: "Name",
    },
    {
      field: "email",
      header: "Email",
    },
    {
      field: "program",
      header: "Programs",
      body: (data: IUser) => {
        return (
          <>
            {data.program?.map((program: any, index: number) => (
              <span key={index} style={{ display: "inline-block", margin: "5px 0", backgroundColor: "#f5f5f5", padding: "5px 10px", borderRadius: "5px" }}>{program.name}</span>
            ))}
          </>
        );
      },
    },

    {
      field: "isActive",
      header: "Status",
      body: (data: IUser) => <SelectButtonToggle data={data} />,
    },
    {
      field: "",
      header: "Update Student",
      body: (data: IUser) => (
        <DialogModel title="Update Student" label="Update" width="75vw">
          <UpdateUser rowData={data} onSave={reFetch} />
        </DialogModel>
      )
    },
    {
      field: "createdAt",
      header: "CreatedAt",
      body: dateCreatedAtBodyTemplate,
    },
    {
      field: "updatedAt",
      header: "updatedAt",
      body: dateUpdatedAtBodyTemplate,
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card className="mb-4">
        <TabBar>
          <DialogModel title="Add Student" label="Add Student" width="60vw">
            <AddUser onSave={reFetch} />
          </DialogModel>
        </TabBar>
      </Card>
      <Card className="mb-4">
        <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
          {isError === false ? (
            <>
              <Box>
                <Table data={data} columns={columns} loading={loading} />
                <PaginatorPages
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </Box>
            </>
          ) : (
            <div className="grid justify-content-center align-content-center">
              <AlertBox status="error" description="Something went wrong. Please try again later." title="Error" />
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};


export default Users;
