
import { Image } from 'primereact/image';

interface IProps {
    src : string
}

export default function ShortImage({src}:IProps) {

    return (
        <div className="card flex justify-content-center">
            <Image src={`${src}`} zoomSrc={`${src}`} alt="Image" width="80" height="60" preview />
        </div>
    )

}
        