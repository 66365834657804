import { IHeadersDataPagination } from "interfaces/pagination";
import { IColumnMeta } from "interfaces/table";
import { useEffect, useState } from "react";
import DialogModel from "shared/DialogModel";
import { dateCreatedAtBodyTemplate, dateUpdatedAtBodyTemplate, statusActiveBodyTemplate } from "utils/bodyTemlates";
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import Table from "shared/Table";
import PaginatorPages from "shared/Paginator";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import AlertBox from "shared/AlertBox";
import TabBar from "shared/TabBar";
import UpdateProgram from "./components/UpdateProgram";
import AddProgram from "./components/AddProgram";
import { IProgram } from "interfaces/program";


const Programs = () => {

  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState<IHeadersDataPagination>({ paginationcurrent: 1, paginationlimit: 20, paginationtotal: 0, });
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const { sharedData } = useAuth()
  const programsAPI = environments.domain + sharedData?.apisList?.WebSite?.Program?.General
  const reFetch = () => {
    getData()
  };


  useEffect(() => {
    getData();
  }, [pagination.paginationcurrent, pagination.paginationlimit]);

  const getData = async () => {
    setLoading(true);
    const response: IApiMetaData = await callApi("GET", programsAPI, {}, pagination, { populatefields: "categories", populateselected: "name" });
    if (response.Status === 200) {
      setPagination((prevPagination: any) => ({
        ...prevPagination,
        paginationtotal: response.Pagination.total,
      }));
      setData(() => response.Data.Content);
    } else {
      setIsError(true);
    }
    setLoading(false);
  };
  const columns: IColumnMeta<IProgram>[] = [
    {
      field: "name",
      header: "Name",
    },
    {
      field: "categories",
      header: "Modules",
      body: (data: any) => {
        return (
          <>
            {data.categories?.map((category: any, index: number) => (
              <span key={index} style={{ display: "inline-block", margin: "5px 0", backgroundColor: "#f5f5f5", padding: "5px 10px", borderRadius: "5px" }}>{category.name}</span>
            ))}
          </>
        );
      },
    },
    {
      field: "isActive",
      header: "Status",
      body: statusActiveBodyTemplate,
    },
    {
      field: "createdAt",
      header: "CreatedAt",
      body: dateCreatedAtBodyTemplate,
    },
    {
      field: "updatedAt",
      header: "updatedAt",
      body: dateUpdatedAtBodyTemplate,
    },
    {
      field: "edit",
      header: "Edit",
      body: (data: any) => {
        return <DialogModel title="Update Program" icon="pi pi-cog" width="60vw" severity="secondary">
          <UpdateProgram rowData={data} onSave={reFetch} />
        </DialogModel>
      },
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card className="mb-4">
        <TabBar>
          <DialogModel title="Add Program" label="Add Program" width="60vw">
            <AddProgram onSave={reFetch} />
          </DialogModel>
        </TabBar>
      </Card>

      <Card  >
        <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
          {isError === false ? (
            <>
              <Box>
                <Table data={data} columns={columns} loading={loading} />
                <PaginatorPages
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </Box>
            </>
          ) : (
            <div className="grid justify-content-center align-content-center">
              <AlertBox status="error" description="Something went wrong. Please try again later." title="Error" />
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};


export default Programs;
