import { Button } from 'primereact/button';

interface IProps {
  severity?: 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help'  ;
  label?: string;
  rounded?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  raised?: boolean;
  icon?: string;
  autoFocus?: boolean;
  text?: boolean;
}



function ButtonRounded({label, rounded=true, severity, onClick, disabled=false, className,raised=true ,icon,autoFocus=false,text=false}: IProps) {
  return (
    <Button label={label} onClick={onClick} disabled={disabled} className={className} severity={severity} rounded={rounded} text={text} raised={raised} icon={icon} autoFocus={autoFocus} />

  )
}

export default ButtonRounded
