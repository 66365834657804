
export const apiRoutes = {

    auth :{
        login: '/api/auth/adminLogin',
        checkAdmin : '/api/auth/checkAdmin',
    },
    sheardData: {
        get: '/api/shared',
    }

}
