import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import Login from "views/auth/login";
import { FaFolderOpen, FaUsers } from "react-icons/fa";
import { GiWhiteBook } from "react-icons/gi";
import Administrators from "views/admin/adminstretors";
import Users from "views/admin/users";
import { HiAcademicCap } from "react-icons/hi2";
import Programs from "views/admin/programs";
import { TiFlowChildren } from "react-icons/ti";
import { useAuth } from "contexts/auth";
import Modules from "views/admin/modules";
import Categories from "views/admin/categories";
import Materials from "views/admin/materials";
import UpdateMaterial from "views/admin/materials/components/UpdateMaterial";
import AddMaterial from "views/admin/materials/components/AddMaterial";

type RoutesType = {
  name: string;
  layout: string;
  path: string;
  icon: JSX.Element;
  element: JSX.Element;
  visibleMenu?: boolean;
  secondary?: boolean;
};

const AppRoutes = (): RoutesType[] => {
  const { sharedData } = useAuth();
  const isSuperAdmin = sharedData?.user?.role === "superAdmin";

  const routes: RoutesType[] = [
    {
      name: "Main Dashboard",
      layout: "/admin",
      path: "home",
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      element: <MainDashboard />,
      visibleMenu: true,
      secondary: true,
    },
    {
      name: "Login",
      layout: "/auth",
      path: "login",
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      element: <Login />,
      visibleMenu: false,
    },
    {
      name: "Programs",
      layout: "/admin",
      path: "programs",
      icon: (
        <Icon as={HiAcademicCap} width="20px" height="20px" color="inherit" />
      ),
      element: <Programs />,
      visibleMenu: true,
      secondary: true,
    },
    {
      name: "Modules",
      layout: "/admin",
      path: "modules",
      icon: (
        <Icon as={FaFolderOpen} width="20px" height="20px" color="inherit" />
      ),
      element: <Modules />,
      visibleMenu: true,
      secondary: true,
    },
    {
      name: "Categories",
      layout: "/admin",
      path: "categories",
      icon: (
        <Icon as={TiFlowChildren} width="20px" height="20px" color="inherit" />
      ),
      element: <Categories />,
      visibleMenu: true,
      secondary: true,
    },
    {
      name: "Materials",
      layout: "/admin",
      path: "material",
      icon: (
        <Icon as={GiWhiteBook} width="20px" height="20px" color="inherit" />
      ),
      element: <Materials />,
      visibleMenu: true,
      secondary: true,
    },
    {
      name: "Add Material",
      layout: "/admin",
      path: "material/add",
      icon: (
        <Icon as={GiWhiteBook} width="20px" height="20px" color="inherit" />
      ),
      element: <AddMaterial />,
      visibleMenu: false,
      secondary: false,
    },
    {
      name: "Update Material",
      layout: "/admin",
      path: "material/update",
      icon: (
        <Icon as={GiWhiteBook} width="20px" height="20px" color="inherit" />
      ),
      element: <UpdateMaterial />,
      visibleMenu: false,
      secondary: false,
    },
    {
      name: "Students",
      layout: "/admin",
      path: "users",
      icon: (
        <Icon as={FaUsers} width="20px" height="20px" color="inherit" />
      ),
      element: <Users />,
      visibleMenu: true,
      secondary: true,
    },
    ...(isSuperAdmin
      ? [
          {
            name: "Administrators",
            layout: "/admin",
            path: "administrators",
            icon: (
              <Icon
                as={MdOutlineAdminPanelSettings}
                width="20px"
                height="20px"
                color="inherit"
              />
            ),
            element: <Administrators />,
            visibleMenu: true,
            secondary: true,
          },
        ]
      : []),
  ];

  return routes;
};

export default AppRoutes;
