import React, { useEffect, useMemo, useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import Message from 'shared/Message';
import ButtonRounded from 'shared/Button';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'contexts/auth';
import { environments } from 'constants/environments';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { callApi } from 'apiServices/callApi';
import UploadImage from 'shared/UploadImage';
import {  IUpdateBookInputs, updateBookSchema,} from 'schema/book';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips, ChipsChangeEvent } from 'primereact/chips';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { Box, SimpleGrid , Text, useColorModeValue } from '@chakra-ui/react';
import {  useFieldArray } from "react-hook-form";
import Card from 'components/card/Card';
import { useLocation } from 'react-router-dom';
interface DropdownItem {
    name: string;
    value: boolean | string;
}

const UpdateMaterial = () => {
    const [dropdownBookStatus, setDropdownBookStatus] = useState<DropdownItem | null>(null);
    const [dropdownViewStatus, setDropdownViewStatus] = useState<DropdownItem | null>(null);
    const [dropdownTypeDoc, setDropdownTypeDoc] = useState<DropdownItem | null>(null);
    const [dropdownSource, setDropdownSource] = useState<DropdownItem | any>(null);
    const [doc, setDoc] = useState<File>({} as File);
    const [cover, setCover] = useState<File>({} as File);
    const [isResponse, setIsResponse] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategorySelected, setSubCategorySelected] = useState<any>(null);
    const [result, setResult] = useState<any>({});

    const [attachmentTypes, setAttachmentTypes] = useState<string[]>([]);
    const { sharedData } = useAuth();
    const bookAPI = environments.domain + sharedData?.apisList?.WebSite?.Item?.General;
    const categoryAPI = environments.domain + sharedData?.apisList?.WebSite?.SubCategory?.General;
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState<string[]>([]);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const location = useLocation();
    const bookData  = location.state || {};

    useEffect(() => {
        setSubCategorySelected(bookData?.subCategory?._id)
        setDropdownTypeDoc(() => bookData?.type)
        setAttachmentTypes(() => bookData?.attachments.map((a: any) => a.typeAttachment))
        setDropdownViewStatus(() => bookData?.isTopView)
        setDropdownBookStatus(() => bookData?.isActive)
        setDescription(() => bookData?.description)
        setDropdownSource(() => bookData?.isExternal)
        setKeywords(() => bookData?.keywords.map((k: any) => k))
    },[])
    useEffect(() => {
        
        const getData = async () => {
            try {
                const response: IApiMetaData = await callApi("GET", categoryAPI, {}, {}, { select: "name" });
                setSubCategories(() =>
                    response?.Data?.Content?.map((subCategory: any) => {
                        return { name: subCategory.name, value: subCategory._id };
                }))
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };
        getData();
    }, [categoryAPI]);

    const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm<IUpdateBookInputs>({
        
        resolver: zodResolver(updateBookSchema),
        mode: "onChange",
        values: { ...bookData , subCategory: subCategorySelected },
    });

    const onSubmit: SubmitHandler<IUpdateBookInputs> = async (data) => {
        try {
            
            if (dropdownSource?.value === false) {
                data.externalLink = undefined;
            }

            setIsResponse(false);
            let dataForm: IUpdateBookInputs = {
                ...data,
                cover: cover,
                subCategory: subCategorySelected,
            };
            if (dataForm.isExternal === true) {
                dataForm.externalLink = data.externalLink;
                delete dataForm.doc;
            } else {
                dataForm.doc = doc;
                delete dataForm.externalLink;
            }
            
            
            const response: IApiMetaData = await callApi("PUT", bookAPI, dataForm, {}, { id: bookData._id }, true);
            
            setResult(response);
            setIsResponse(true);
        } catch (error) {
            console.error("Error submitting form: ", error);
            setResult({ Status: 500, Message: "An unexpected error occurred" });
            setIsResponse(true);
        }
    };

    const bookStatus: DropdownItem[] = useMemo(
        () => [
            { name: 'Active', value: true },
            { name: 'Not Active', value: false },
        ],
        []
    );

    const viewStatus: DropdownItem[] = useMemo(
        () => [
            { name: 'Top', value: true },
            { name: 'Normal', value: false },
        ],
        []
    );

    const source: DropdownItem[] = useMemo(
        () => [
            { name: 'External', value: true },
            { name: 'Internal', value: false },
        ],
        []
    );

    const docType: DropdownItem[] = useMemo(
        () => [
            { name: 'Video', value: "video" },
            { name: 'PPT', value: "ppt" },
            { name: 'PDF', value: "pdf" },
        ],
        []
    );

    const handleStatusChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDropdownBookStatus(e.value);
    };

    const handleStatusViewChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDropdownViewStatus(e.value);
    };

    const handleDescriptionChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDescription(e.value);
    };

    const handleDocTypeChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDropdownTypeDoc(e.value);
    };

    const handleAttachmentDocTypeChange = (e: DropdownChangeEvent, onChange: (e: any) => void, index: number) => {
        onChange(e);
        const newAttachmentTypes = [...attachmentTypes];
        newAttachmentTypes[index] = e.value;
        setAttachmentTypes(newAttachmentTypes);
    };

    const handleSubCategoryChange = (e: MultiSelectChangeEvent | any, onChange: (e: any) => void) => {
        onChange(e);
        setSubCategorySelected(e.value);
    };

    const handleSourceChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDropdownSource(e.value);
    };

    const handleKeywordsChange = (e: ChipsChangeEvent | any, onChange: (e: any) => void) => {
        onChange(e);
        setKeywords(e.value);
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'attachments'
    });

    // useEffect(() => {
    //     setAttachmentTypes(fields.map(() => ""));
    // }, [fields]);

    const handleAddAttachment = () => {
        append({ name: '', url: '', typeAttachment: '' });
        setAttachmentTypes((prev) => [...prev, '']);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <SimpleGrid columns={{ lg: 1, md: 1 }} my="20px">
                    <Box>
                        <div className="grid justify-content-center">
                            <div className='col-12'>
                                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' px={'10px'}>
                                    Add Book
                                </Text>
                            </div>
                            <div className="col-12">
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="p-fluid formgrid p-4 grid ">
                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="title">Title<span className='text-red-600'>*</span></label>
                                                <InputText id="title" type="text" {...register("title")} />
                                                {errors?.title && <small className='text-error text-red-400 '>{errors.title.message}</small>}
                                            </div>
                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="supTitle">Sub Title<span className='text-red-600'>*</span></label>
                                                <InputText id="supTitle" type="text" {...register("supTitle")} />
                                                {errors?.supTitle && <small className='text-error text-red-400'>{errors.supTitle.message}</small>}
                                            </div>
                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="keywords">Keywords</label>
                                                <Controller
                                                    name="keywords"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <Chips value={keywords} onChange={(e: any) => handleKeywordsChange(e, onChange)} />
                                                            {errors?.keywords && <small className='text-error text-red-400'>{errors.keywords.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="status">Status<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="isActive"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <Dropdown
                                                                id="status"
                                                                value={dropdownBookStatus}
                                                                options={bookStatus}
                                                                onChange={(e) => handleStatusChange(e, onChange)}
                                                                optionLabel="name"
                                                                optionValue='value'
                                                                placeholder="Select Status"
                                                            />
                                                            {errors?.isActive && <small className='text-error text-red-400'>{errors.isActive.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="field col-12">
                                                <label htmlFor="attachments">Attachments</label>
                                                {fields.map((field, index) => (
                                                    <div key={field.id} className='mb-5' id='attachments'>
                                                        <div className='grid mb-1'>
                                                            <div className='col-2'>
                                                                <Controller
                                                                    name={`attachments.${index}.typeAttachment` as const}
                                                                    control={control}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Dropdown
                                                                            id={`attachments.${index}.typeAttachment`}
                                                                            value={attachmentTypes[index]}
                                                                            options={docType}
                                                                            onChange={(e) => handleAttachmentDocTypeChange(e, onChange, index)}
                                                                            optionLabel="name"
                                                                            optionValue='value'
                                                                            placeholder="Select Attachment Type"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>

                                                            <div className='col-3'>
                                                                <Controller
                                                                    name={`attachments.${index}.name` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <InputText {...field} placeholder="Attachment Name" required type="text" />
                                                                    )}
                                                                />
                                                            </div>

                                                            <div className='col-7'>
                                                                <Controller
                                                                    name={`attachments.${index}.url` as const}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <InputText {...field} placeholder="Attachment URL" required type="text" />
                                                                    )}
                                                                />
                                                            </div>

                                                            <div className='col-2'>
                                                                <button type="button" onClick={() => {
                                                                    remove(index);
                                                                    setAttachmentTypes((prev) => prev.filter((_, i) => i !== index));
                                                                }}>❌</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                                <button type="button" onClick={handleAddAttachment} style={{ display: 'block' }}>
                                                    ➕
                                                </button>
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="isTopView">Status View<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="isTopView"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <Dropdown
                                                                id="isTopView"
                                                                value={dropdownViewStatus}
                                                                options={viewStatus}
                                                                onChange={(e) => handleStatusViewChange(e, onChange)}
                                                                optionLabel="name"
                                                                optionValue='value'
                                                                placeholder="Select Status View"
                                                            />
                                                            {errors?.isTopView && <small className='text-error text-red-400'>{errors.isTopView.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="type">Material Type<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="type"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <Dropdown
                                                                id="type"
                                                                value={dropdownTypeDoc}
                                                                options={docType}
                                                                onChange={(e) => handleDocTypeChange(e, onChange)}
                                                                optionLabel="name"
                                                                optionValue='value'
                                                                placeholder="Select Material Type"
                                                            />
                                                            {errors?.type && <small className='text-error text-red-400'>{errors.type.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="source">Source<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="isExternal"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <Dropdown
                                                                id="source"
                                                                value={dropdownSource}
                                                                options={source}
                                                                onChange={(e) => handleSourceChange(e, onChange)}
                                                                optionLabel="name"
                                                                optionValue='value'
                                                                placeholder="Select source"
                                                            />
                                                            {errors?.isExternal && <small className='text-error text-red-400'>{errors.isExternal.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="externalLink">Source Link</label>
                                                <InputText id="externalLink" type="text" {...register("externalLink")} />
                                                {errors?.externalLink && <small className='text-error text-red-400'>{errors.externalLink.message}</small>}
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="category">Category<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="subCategory"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                        <Dropdown
                                                                id="type"
                                                                value={subCategorySelected}
                                                                options={subCategories}
                                                                onChange={(e) => handleSubCategoryChange(e, onChange)}
                                                                optionLabel="name"
                                                                optionValue='value'
                                                                />
                                                            {errors?.subCategory && <small className='text-error text-red-400'>{errors.subCategory.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="description">Description<span className='text-red-600'>*</span></label>
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <>
                                                            <InputTextarea
                                                                autoResize
                                                                value={description}
                                                                onChange={(e: any) => handleDescriptionChange(e, onChange)}
                                                                rows={5} cols={30}
                                                            />
                                                            {errors?.description && <small className='text-error text-red-400'>{errors.description.message}</small>}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="doc">Doc</label>
                                                <UploadImage setImage={setDoc} imageSrc={bookData.doc}/>
                                            </div>
                                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <label htmlFor="cover">Cover<span className='text-red-600'>*</span></label>
                                                <UploadImage setImage={setCover} imageSrc={bookData.cover}/>
                                            </div>

                                            <div className='text-right mt-4 ml-auto'>
                                                <ButtonRounded disabled={isSubmitting} label="Save" raised severity="success" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {isResponse &&
                                <div className='col-8'>
                                    <Message
                                        message={result?.Status === 201 ? result?.Message : result?.response?.data?.Error}
                                        severity={result?.Status === 201 ? result?.Severity : "error"}
                                    />
                                </div>
                            }
                        </div>
                    </Box>
                </SimpleGrid>
            </Card>
        </Box>
    );
};

export default UpdateMaterial;
