import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import Message from 'shared/Message';
import ButtonRounded from 'shared/Button';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'contexts/auth';
import { environments } from 'constants/environments';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { callApi } from 'apiServices/callApi';
import { addAdministratorSchema, IAddAdministratorSchemaInputs } from 'schema/admin';

interface IProps {
  onSave: () => void;
}
const AddAdministrator = ({onSave}: IProps) => {
  const [isResponse, setIsResponse] = useState(false);
  const [result, setResult] = useState<any>({});
  const { sharedData } = useAuth();
  const addAdministratorAPI = environments.domain + sharedData?.apisList?.Dashboard?.Auth?.AddAdmin;

  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm<IAddAdministratorSchemaInputs>({
    resolver: zodResolver(addAdministratorSchema),
    mode: 'onChange'
  });

  const onSubmit: SubmitHandler<IAddAdministratorSchemaInputs> = async (data) => {
      setIsResponse(false);
      
      let {confirmPassword , ...others} = data;
      const response: IApiMetaData = await callApi('POST', addAdministratorAPI, others, {}, {}, false);
      
    setResult(response);
    onSave();
    setIsResponse(true);
  };

  return (
    <div className="grid justify-content-center">
      <div className="col-12">
        <div className="px-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-fluid formgrid p-4 grid shadow-2 border-round-md">
              <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                <label htmlFor="name">Name</label>
                <InputText id="name" type="text" {...register('name')} />
                {errors.name && <small className='text-error text-red-600'>{errors.name.message}</small>}
              </div>

              <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                <label htmlFor="email">Email</label>
                <InputText id="email" type="email" {...register('email')} />
                {errors.email && <small className='text-error text-red-600'>{errors.email.message}</small>}
              </div>

              <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                <label htmlFor="password">Password</label>
                <InputText id="password" type="password" {...register('password')} />
                {errors.password && <small className='text-error text-red-600'>{errors.password.message}</small>}
              </div>

              <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                <label htmlFor="confirm-password">Confirm Password</label>
                <InputText id="confirm-password" type="password" {...register('confirmPassword')} />
                {errors.confirmPassword && <small className='text-error text-red-600'>{errors.confirmPassword.message}</small>}
              </div>
            </div>

            <div className='text-right mt-4'>
              <ButtonRounded disabled={isSubmitting} label="Save" raised severity="success" />
            </div>
          </form>
        </div>
      </div>

      {isResponse && (
        <div className='col-8'>
          <Message
            message={result?.Status === 201 ? result?.Message : result?.response?.data?.Error}
            severity={result?.Status === 201 ? 'success' : 'error'}
          />
        </div>
      )}
    </div>
  );
};

export default AddAdministrator;
