import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import Message from 'shared/Message';
import ButtonRounded from 'shared/Button';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'contexts/auth';
import { environments } from 'constants/environments';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { callApi } from 'apiServices/callApi';
import {IUpdateUserInputs, updateUserSchema } from 'schema/user';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface IProps {
  onSave: () => void;
  rowData: any,
}
const UpdateUser = ({ rowData, onSave }: IProps) => {
  const [isResponse, setIsResponse] = useState(false);
  const [result, setResult] = useState<any>({});
  const { sharedData } = useAuth();
  const [programs, setProgram] = useState([]);
  const [programSelected, setProgramSelected] = useState<any[]>([]);

  const changeProgramsUserAPI = environments.domain + sharedData?.apisList?.Dashboard?.User?.changeProgramsUser;
  const programsAPI = environments.domain + sharedData?.apisList?.WebSite?.Program?.General


  useEffect(() => {
    
    const getData = async () => {
      const response: IApiMetaData = await callApi("GET", programsAPI, {}, {}, { select: "name" });
      setProgram(() =>
        response?.Data?.Content?.map((program: any) => {
          return { name: program.name, value: program._id };
        }))
    }
    getData()
    setProgramSelected(rowData?.program?.map((program: any) => program._id))
  }, [])

  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm<IUpdateUserInputs>({
    resolver: zodResolver(updateUserSchema),
    mode: 'onChange',
    defaultValues: rowData

  });

  const onSubmit: SubmitHandler<IUpdateUserInputs> = async (data) => {
    setIsResponse(false);
    const dataForm = {
      ...data,
      id: String(rowData?._id)
    }

    const response: IApiMetaData = await callApi('PUT', changeProgramsUserAPI, dataForm, {}, {  }, false);

    setResult(response);

    onSave();
    setIsResponse(true);
  };

  const handleSelectProgram = (e: MultiSelectChangeEvent, onChange: (e: any) => void) => {
    onChange(e.value);
    setProgramSelected(e.value);
  };


  return (
    <div className="grid justify-content-center">
      <div className="col-12">
        <div className="px-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-fluid formgrid p-4 grid shadow-2 border-round-md">

              <div className="field col-12 ">
                <label htmlFor="program">Programs</label>
                <Controller
                  name="program"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <>
                      <MultiSelect value={programSelected} onChange={(e: any) => handleSelectProgram(e, onChange)} options={programs} display="chip" optionLabel="name" optionValue='value'
                        placeholder="Select Programs" maxSelectedLabels={3} className="w-full " />
                      {errors?.program && <small className='text-error text-red-600' >{errors?.program?.message}</small>}
                    </>
                  )}
                />
              </div>
            </div>

            <div className='text-right mt-4'>
              <ButtonRounded disabled={isSubmitting} label="Save" raised severity="success" />
            </div>
          </form>
        </div>
      </div>

      {isResponse && (
        <div className='col-8'>
          <Message
            message={result?.Status === 201 ? result?.Message : result?.response?.data?.Error}
            severity={result?.Status === 201 ? 'success' : 'error'}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateUser;
