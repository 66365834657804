
import React, { ReactNode, useState } from "react";
import { Dialog } from 'primereact/dialog';
import ButtonRounded from "./Button";


interface IProps {
    children: ReactNode;
    title: string;
    label?: string;
    width?: string;
    icon?: string
    text?: boolean
    customButton?: ReactNode
    severity?: "secondary" | "success" | "info" | "warning" | "danger" | "help";
    className?: string;
    onClickSave?: () => void;
    isFooter?: boolean;
}
export default function DialogModel({children, title,label,icon,severity, width='50vw', className="p-button-text",onClickSave,isFooter=false,text,customButton}:IProps) {
    const [visible, setVisible] = useState<boolean>(false);

    const footerContent = (
        <div>
            <ButtonRounded label="No" icon="pi pi-times" severity="danger" onClick={() => setVisible(false)} className={className} />
            <ButtonRounded label="Save" icon="pi pi-check" severity="success" onClick={() => [onClickSave  , setVisible(false)]} autoFocus />
        </div>
    );

    return (
        <>
            
            {customButton ?<div onClick={() => setVisible(true)}>{customButton}</div> 
             : 
             <ButtonRounded icon={icon} label={label} severity={severity} onClick={() => setVisible(true)} text={text}/>
            }
            <Dialog header={title} visible={visible} style={{ width: width }} onHide={() => {if (!visible) return; setVisible(false); }} footer={isFooter&&footerContent}>
                {children}
            </Dialog>
        </>
    )
}
        