import { IHeadersDataPagination } from "interfaces/pagination";
import { IColumnMeta } from "interfaces/table";
import { useEffect, useState } from "react";
import DialogModel from "shared/DialogModel";
import { dateCreatedAtBodyTemplate, dateUpdatedAtBodyTemplate, statusActiveBodyTemplate } from "utils/bodyTemlates";
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import Table from "shared/Table";
import PaginatorPages from "shared/Paginator";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import AlertBox from "shared/AlertBox";
import TabBar from "shared/TabBar";
import { ISubCategory } from "interfaces/subCategory";
import AddCategory from "./components/AddCategory";
import UpdateCategory from "./components/UpdateCategory";


const Categories  = () => {

  const [data, setData]                 = useState<any[]>([]);
  const [pagination, setPagination]     = useState<IHeadersDataPagination>({paginationcurrent: 1,paginationlimit: 20,paginationtotal: 0,});
  const [loading, setLoading]           = useState<boolean>(true);
  const [isError, setIsError]           = useState(false);
  const {sharedData}                    = useAuth()
  const subCategoryAPI                     = environments.domain + sharedData?.apisList?.WebSite?.SubCategory?.General

  const reFetch = () => {
    getData()
  };

  useEffect(() => {
    
    getData();
  }, [pagination.paginationcurrent, pagination.paginationlimit]);
  const getData = async () => {
    setLoading(true);
    
    const response : IApiMetaData = await callApi("GET", subCategoryAPI,{},pagination,{populatefields:"category",populateselected:"name"});
    if (response.Status === 200) {
      setPagination((prevPagination: any) => ({
        ...prevPagination,
        paginationtotal: response.Pagination.total,
      }));
      setData(() => response.Data.Content);
    } else {
      setIsError(true);
    }
    setLoading(false);
  };
  const columns: IColumnMeta<ISubCategory>[] = [
    {
      field: "name",
      header: "Name",
    },
    {
      field: "category.name",
      header: "Modules",
    },
    {
      field: "isActive",
      header: "Status",
      body: statusActiveBodyTemplate,
    },
    {
      field: "createdAt",
      header: "CreatedAt",
      body: dateCreatedAtBodyTemplate,
    },
    {
      field: "updatedAt",
      header: "updatedAt",
      body: dateUpdatedAtBodyTemplate,
    },
    {
      field: "edit",
      header: "Edit",
      body: (data: ISubCategory) => {
        return<DialogModel title="Update Category" icon="pi pi-cog"  width="60vw" severity="secondary">
          <UpdateCategory rowData={data} onSave={reFetch}/>
        </DialogModel>
      },
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card className="mb-4">
        <TabBar>
          <DialogModel title="Add Category" label="Add Category" width="60vw">
            <AddCategory onSave={reFetch}/>
          </DialogModel>
        </TabBar>
      </Card>
      
      <Card>
        <div style={{ width: "100%" , marginTop: "20px", marginBottom: "20px" }}>
          {isError === false ? (
            <>
              <Box>
                <Table data={data} columns={columns} loading={loading} />
                <PaginatorPages
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </Box>
            </>
          ) : (
            <div className="grid justify-content-center align-content-center">
                <AlertBox status="error" description="Something went wrong. Please try again later." title="Error" />
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};



export default Categories ;
