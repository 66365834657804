import { z } from "zod";

export const addAdministratorSchema = z.object({
    name: z.string().min(1, { message: "Name is Required" }),
    email: z.string().min(1, { message: "Email is Required" }).email({ message: "Invalid Email" }),
    password: z.string().min(6, { message: "Password must be at least 6 characters" }),
    confirmPassword: z.string().min(6, { message: "Confirm Password must be at least 6 characters" }),
  }).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], 
  });

export const changePasswordSchema = z.object({
    email: z.string().email({ message: "Invalid Email" }),
    oldPassword: z.string(),
    newPassword: z.string().min(6, { message: "Password must be at least 6 characters" }),
    confirmPassword: z.string().min(6, { message: "Confirm Password must be at least 6 characters" }),
  }).refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], 
  });

  export const updateAdministratorSchema = z.object({
    name: z.string().optional(),
    email: z.string().email({ message: "Invalid Email" }).optional(),
    password: z.string().optional(),
    confirmPassword: z.string().optional(),
  }).refine((data) => {
    if (data.password) {
      return data.confirmPassword && data.password === data.confirmPassword;
    }
    return true;
  }, {
    message: "Passwords do not match",
    path: ["confirmPassword"], 
  }).refine((data) => {
    if (data.password) {
      return !!data.confirmPassword;
    }
    return true;
  }, {
    message: "Confirm Password is required when Password is provided",
    path: ["confirmPassword"], 
  });




export type IAddAdministratorSchemaInputs = z.infer<typeof addAdministratorSchema>
export type IUpdateAdministratorInputs = z.infer<typeof updateAdministratorSchema>
export type IChangePasswordInputs = z.infer<typeof changePasswordSchema>
