import React, { useEffect, useMemo, useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import Message from 'shared/Message';
import ButtonRounded from 'shared/Button';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'contexts/auth';
import { environments } from 'constants/environments';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { callApi } from 'apiServices/callApi';
import { IUpdateSubCategoryInputs, updateSubCategorySchema } from 'schema/subCategory';

interface DropdownItem {
    name: string;
    value: boolean | string;
}

interface IProps {
    rowData: any,
    onSave: () => void
}

const UpdateCategory= ({ rowData ,onSave}: IProps) => {
    const [dropdownCategoryStatus, setDropdownCategoryStatus]   = useState<DropdownItem | null>(rowData?.isActive);
    const [categorySelected , setCategorySelected]              = useState(rowData?.category?._id);
    const [categories , setCategories]                          = useState([]);
    const [isResponse, setIsResponse]                           = useState(false);
    const [result, setResult]                                   = useState<any>({})
    const {sharedData}                                          = useAuth()
    const categoryAPI                                           = environments.domain + sharedData?.apisList?.WebSite?.Category?.General
    const subCategoryAPI                                        = environments.domain + sharedData?.apisList?.WebSite?.SubCategory?.General

    useEffect(() => { 
        const getData = async () => {
            const response : IApiMetaData = await callApi("GET", categoryAPI,{},{});
            setCategories(() =>
                response?.Data?.Content?.map((category: any) => {
                  return { name: category.name, value: category._id };
                }))
        }
        getData()
    },[])

    
    
    const {handleSubmit,register,control,formState:{errors,isSubmitting}} = useForm<IUpdateSubCategoryInputs>({
        resolver: zodResolver(updateSubCategorySchema),
        values: { ...rowData,category: rowData?.category?._id} as IUpdateSubCategoryInputs,
        mode :"onChange"
    })

    
    const onSubmit: SubmitHandler<IUpdateSubCategoryInputs> = async (data: IUpdateSubCategoryInputs) => {
        setIsResponse(false)
        const response : IApiMetaData = await callApi("PUT", subCategoryAPI,data,{},{id:String(rowData?._id)});
        
        setResult(()=>response)
        onSave()
        setIsResponse(()=>true)
    }

    const status: DropdownItem[] = useMemo(
        () => [
            { name: 'Active', value: true },
            { name: 'Not Active', value:false},
        ],
        []
    );

    const handleStatusChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e); 
        setDropdownCategoryStatus(e.value);
    };

    const handleSelectProgram = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e); 
        setCategorySelected(e.value);
    };

    return (
        <div className="grid justify-content-center">
            <div className="col-12">
                <div className="px-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid formgrid p-4  grid shadow-2 border-round-md">

                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" type="text" {...register("name")} />
                                {errors?.name && <small className='text-error text-red-600' >{errors?.name?.message}</small>}
                            </div>

                            <div className="field col-12 md:col-6">
                                <label htmlFor="Module">Module</label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field:{ onChange} }) => (
                                        <>
                                            <Dropdown
                                            id="category"
                                            value={categorySelected}
                                            options={categories}
                                            onChange={(e) => handleSelectProgram(e, onChange)}
                                            optionLabel="name"
                                            optionValue='value'
                                            placeholder="Select Module Name"
                                        />
                                        {errors?.category && <small className='text-error text-red-600' >{errors?.category?.message}</small>}
                                        </>
                                    )}
                                    />
                          </div>

                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                <label htmlFor="status">Status</label>
                                <Controller
                                name="isActive"
                                control={control}
                                render={({ field:{ onChange} }) => (
                                    <>
                                        <Dropdown
                                        id="status"
                                        value={dropdownCategoryStatus}
                                        options={status}
                                        onChange={(e) => handleStatusChange(e, onChange)}
                                        optionLabel="name"
                                        optionValue='value'
                                        placeholder="Select Status"
                                    />
                                    {errors?.isActive && <small className='text-error text-red-600' >{errors?.isActive?.message}</small>}
                                    </>
                                )}
                                />
                            </div>

                            <div className='text-right mt-4 ml-auto'>
                                <ButtonRounded disabled={isSubmitting} label="Save" raised severity="success" />
                            </div>
                        
                        </div>
                    </form>
                </div>
            </div>
            {
                isResponse &&
                <>
                    <div className='col-8'>
                        <Message
                                message={result?.Status === 201 ? result?.Message : result?.response?.data?.Error}
                                severity={result?.Status === 201 ? result?.Severity : "error"}
                        />
                    </div>
                </>
            }

        </div>
    );
};

export default UpdateCategory;
