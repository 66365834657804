// import jsPDF from 'jspdf';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import React, { useRef, useState } from 'react';

interface IProps {
    data: any[];
    columns: any[];
    loading: boolean;
    filterValue?: string;
    minWidth?: string;
}

export default function Table({ data = [], columns, loading, minWidth = '250px' }: IProps) {
    const [visibleColumns, setVisibleColumns] = useState<any[]>(columns);
    const [filters, setFilters] = useState<DataTableFilterMeta>(() => defaultFilterValue(columns));
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const dt = useRef<DataTable<any>>(null);

    const onColumnToggle = (event: MultiSelectChangeEvent) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((columns: any) => columns.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
    };
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // const exportCSV = (selectionOnly: any) => {
    //     dt?.current?.exportCSV({ selectionOnly });
    // };

    // const exportPdf = () => {
    //     import('jspdf').then((jsPDF) => {
    //         import('jspdf-autotable').then(() => {
    //             const doc: any = new jsPDF.default('p', 'pt') as jsPDF;

    //             doc?.autoTable(
    //                 visibleColumns.map((col: any) => ({ title: col.header, dataKey: col.field ?? 'sample' })),
    //                 data
    //             );
    //             doc.save('data.pdf');
    //         });
    //     });
    // };

    // const exportExcel = () => {
    //     import('xlsx').then((xlsx) => {
    //         const worksheet = xlsx.utils.json_to_sheet(data);
    //         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, {
    //             bookType: 'xlsx',
    //             type: 'array'
    //         });

    //         saveAsExcelFile(excelBuffer, 'data');
    //     });
    // };

    // const saveAsExcelFile = (buffer: any, fileName: any) => {
    //     import('file-saver').then((module) => {
    //         if (module && module.default) {
    //             let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //             let EXCEL_EXTENSION = '.xlsx';
    //             const data = new Blob([buffer], {
    //                 type: EXCEL_TYPE
    //             });
    //             module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //         }
    //     });
    // };

    const header = (
        <div className="flex align-items-center  justify-content-center sm:justify-content-between gap-2 flex-wrap">
            <div>
                <MultiSelect value={visibleColumns} options={columns} optionLabel="header" onChange={onColumnToggle} style={{ maxWidth: '38vh' }} display="chip" />
            </div>
            <div className="flex align-items-center gap-2 ml-auto flex-wrap justify-content-center">

                {/* <button type="button" onClick={() => exportJSON(data)} data-pr-tooltip="JSON" style={{ marginRight: '10px' }}><BsFiletypeJson size={30} color='#e36c01' /> </button>
                <button type="button" onClick={() => exportCSV(false)} data-pr-tooltip="CSV" style={{ marginRight: '10px' }}> <BsFiletypeCsv size={30} color='#00c100'/>   </button>
                <button type="button" onClick={exportExcel} data-pr-tooltip="XLS" style={{ marginRight: '10px' }}>            <BsFiletypeXlsx size={30} color='green'/>    </button>
                <button type="button" onClick={exportPdf} data-pr-tooltip="PDF" style={{ marginRight: '10px' }}>              <BsFiletypePdf size={30} color='#e10000' />  </button> */}

                <div>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </div>
            </div>
        </div>
    );

    return (
        <div className="card">
            <Tooltip target=".export-buttons>button" position="bottom" />

            {data && (
                <>
                    <DataTable ref={dt} value={data} header={header} tableStyle={{ minWidth: minWidth }} scrollable dataKey="_id" filters={filters} showGridlines loading={loading} globalFilterFields={visibleColumns.map((col) => col.field)}>
                        {visibleColumns.map((col) => (
                            <Column key={col.field} field={col.field} header={col.header} body={col.body}
                                
                                style={{ minWidth: col.field === 'edit' ? '20px' : minWidth, textAlign:"center" }}
                                filter={col.field === 'edit' ? false : true} sortable={col.field === 'edit' ? false : true}
                            />
                        ))}
                    </DataTable>
                </>
            )}
        </div>
    );
}

function defaultFilterValue(columns: any[]) {
    let defaultFilters: DataTableFilterMeta = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
    columns.forEach((col: any) => {
        defaultFilters[col.field] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
    });

    return defaultFilters;
}