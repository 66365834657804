import { IHeadersDataPagination } from "interfaces/pagination";
import { IColumnMeta } from "interfaces/table";
import { useEffect, useState, useCallback } from "react";
import DialogModel from "shared/DialogModel";
import { dateCreatedAtBodyTemplate, dateUpdatedAtBodyTemplate } from "utils/bodyTemlates";
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import Table from "shared/Table";
import PaginatorPages from "shared/Paginator";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import AlertBox from "shared/AlertBox";
import TabBar from "shared/TabBar";
import AddAdministrator from "./components/AddAdministrator";
import SelectButtonToggle from "shared/SelectButtonToggle";
import { IUser } from "interfaces/user";
import UpdateAdministrator from "./components/UpdateAdministrator";

const Administrators = () => {
  const [data, setData] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState<IHeadersDataPagination>({
    paginationcurrent: 1,
    paginationlimit: 20,
    paginationtotal: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const { sharedData } = useAuth();
  const administratorsAPI = environments.domain + sharedData?.apisList?.Dashboard?.User?.General;

  const reFetch = () => {
    fetchData()
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const response: IApiMetaData = await callApi("GET", administratorsAPI, {}, pagination, {
      populatefields: "addedBy updatedBy",
      populateselected: 'name',
      select: "-password",
      target: "admin",
    });

    if (response.Status === 200) {
      setPagination(prevPagination => ({
        ...prevPagination,
        paginationtotal: response.Pagination.total,
      }));
      setData(response.Data.Content);
    } else {
      setIsError(true);
    }
    setLoading(false);
  }, [administratorsAPI, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: IColumnMeta<IUser>[] = [
    { field: "name", header: "Name" },
    { field: "email", header: "Email" },
    { 
      field: "isActive", 
      header: "Status", 
      body: (rowData: IUser) => <SelectButtonToggle data={rowData} /> 
    },
    { 
      field: "createdAt", 
      header: "Created At", 
      body: dateCreatedAtBodyTemplate 
    },
    { 
      field: "updatedAt", 
      header: "Updated At", 
      body: dateUpdatedAtBodyTemplate 
    },
    { 
      field: "", 
      header: "Update Admin", 
      body: (data: IUser) => (
        <DialogModel title="Update Administrator" label="Update" width="75vw">
          <UpdateAdministrator rowData={data} onSave={reFetch} />
        </DialogModel>
      ) 
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card className="mb-4">
        <TabBar>
          <DialogModel title="Add Administrator" label="Add Administrator" width="75vw">
            <AddAdministrator onSave={reFetch}/>
          </DialogModel>
        </TabBar>
      </Card>
      <Card>
        <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
          {!isError ? (
            <Box>
              <Table data={data} columns={columns} loading={loading} />
              <PaginatorPages pagination={pagination} setPagination={setPagination} />
            </Box>
          ) : (
            <div className="grid justify-content-center align-content-center">
              <AlertBox status="error" description="Something went wrong. Please try again later." title="Error" />
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};

export default Administrators;
