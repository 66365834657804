
// Chakra imports
import {  Box } from '@chakra-ui/react';
// Assets


export default function UserReports() {
	// Chakra Color Mode
	// const brandColor = useColorModeValue('brand.500', 'white');
	// const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
		
		</Box>
	);
}
