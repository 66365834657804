import React, { useEffect, useMemo, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import Message from 'shared/Message';
import ButtonRounded from 'shared/Button';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'contexts/auth';
import { environments } from 'constants/environments';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { callApi } from 'apiServices/callApi';
import { createCategorySchema, ICreateCategoryInputs } from 'schema/category';
import { MultiSelect } from 'primereact/multiselect';

interface DropdownItem {
    name: string;
    value: boolean | string;
}
interface IProps {
    onSave: () => void
}
const AddModule = ({ onSave }: IProps) => {
    const [dropdownCategoryStatus, setDropdownCategoryStatus] = useState<DropdownItem | null>(null);
    const [programSelected, setProgramSelected] = useState<any[]>([]); 
    const [programs, setProgram] = useState([]);
    const [isResponse, setIsResponse] = useState(false);
    const [result, setResult] = useState<any>({})
    const { sharedData } = useAuth()
    const categoryAPI = environments.domain + sharedData?.apisList?.WebSite?.Category?.General
    const programsAPI = environments.domain + sharedData?.apisList?.WebSite?.Program?.General


    useEffect(() => {
        const getData = async () => {
            const response: IApiMetaData = await callApi("GET", programsAPI, {}, {}, { select: "name" });
            setProgram(() =>
                response?.Data?.Content?.map((program: any) => {
                    return { name: program.name, value: program._id };
                }))
        }
        getData()
    }, [])

    const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm<ICreateCategoryInputs>({
        resolver: zodResolver(createCategorySchema),
        mode: "onChange"
    })

    const onSubmit: SubmitHandler<ICreateCategoryInputs> = async (data: ICreateCategoryInputs) => {
        setIsResponse(false)
        const response: IApiMetaData = await callApi("POST", categoryAPI, data, {}, {}, false);
        setResult(() => response)
        onSave()
        setIsResponse(() => true)
    }

    const status: DropdownItem[] = useMemo(
        () => [
            { name: 'Active', value: true },
            { name: 'Not Active', value: false },
        ],
        []
    );

    const handleStatusChange = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setDropdownCategoryStatus(e.value);
    };

    const handleSelectProgram = (e: DropdownChangeEvent, onChange: (e: any) => void) => {
        onChange(e);
        setProgramSelected(e.value);
    };




    return (
        <div className="grid justify-content-center">
            <div className="col-12">
                <div className="px-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid formgrid p-4  grid shadow-2 border-round-md">

                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" type="text" {...register("name")} />
                                {errors?.name && <small className='text-error text-red-600' >{errors?.name?.message}</small>}
                            </div>

                            <div className="field col-12 md:col-12 lg:col-6 xl:col-6">
                                <label htmlFor="status">Status</label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <>
                                            <Dropdown
                                                id="status"
                                                value={dropdownCategoryStatus}
                                                options={status}
                                                onChange={(e) => handleStatusChange(e, onChange)}
                                                optionLabel="name"
                                                optionValue='value'
                                                placeholder="Select Status"
                                            />
                                            {errors?.isActive && <small className='text-error text-red-600' >{errors?.isActive?.message}</small>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="field col-12 ">
                                <label htmlFor="program">Program</label>
                                <Controller
                                    name="program"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <>
                                            <MultiSelect value={programSelected} onChange={(e: any) => handleSelectProgram(e, onChange)} options={programs} display="chip" optionLabel="name" optionValue='value'
                                                placeholder="Select Programs" maxSelectedLabels={3} className="w-full " />
                                            {errors?.program && <small className='text-error text-red-600' >{errors?.program?.message}</small>}
                                        </>
                                    )}
                                />
                            </div>
                            <div className='text-right mt-4 ml-auto'>
                                <ButtonRounded disabled={isSubmitting} label="Save" raised severity="success" />
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {
                isResponse &&
                <>
                    <div className='col-8'>
                        <Message
                            message={result?.Status === 201 ? result?.Message : result?.response?.data?.Error}
                            severity={result?.Status === 201 ? result?.Severity : "error"}
                        />
                    </div>
                </>
            }

        </div>
    );
};

export default AddModule;
