import { z } from "zod";

export const createSubCategorySchema = z.object({
    name : z.string().min(1,{message:"Category Name is Required"}),
    category: z.string(),
    isActive: z.boolean(),
});

export const updateSubCategorySchema = z.object({
    name : z.string().optional(),
    category: z.string().optional(),
    isActive: z.boolean().optional(),
});




export type ICreateSubCategoryInputs = z.infer<typeof createSubCategorySchema>
export type IUpdateSubCategoryInputs = z.infer<typeof updateSubCategorySchema>
